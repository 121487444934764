<template>
    <div>
        <v-toolbar dense color="secondary" dark>
            <v-toolbar-title>Files</v-toolbar-title>
        </v-toolbar>
        <v-card outlined flat>
            <v-list v-if="media">
                <template v-for="(file, index) in media">
                    <v-list-item :key="index" class="align-center">
                        <v-list-item-icon>
                            <v-icon large color="primary" class="mt-5"
                                >mdi-file</v-icon
                            >
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div class="d-flex align-center">
                                <a :href="file.url" target="_blank">
                                    <span
                                        style="display: block; width: 430px"
                                        class="text-truncate text-body-1"
                                        >{{ file.name }}</span
                                    ></a
                                >
                                <div
                                    v-if="file.category || selectable"
                                    class="ml-4"
                                >
                                    <div v-if="selectable" style="width: 180px">
                                        <v-select
                                            class="mt-5"
                                            dense
                                            solo
                                            :items="categories"
                                            :value="file.category"
                                            @change="
                                                updateFileCategory(file, $event)
                                            "
                                        >
                                        </v-select>
                                    </div>
                                    <span v-else>{{ file.category }}</span>
                                </div>
                            </div>
                        </v-list-item-content>

                        <v-list-item-action>
                            <a :href="file.url" target="blank">
                                <v-btn icon color="primary">
                                    <v-icon small>mdi-download</v-icon>
                                </v-btn>
                            </a>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider
                        :key="`divider-${index}`"
                        v-if="index < media.length - 1"
                    ></v-divider>
                </template>
            </v-list>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "list-media-files",
    components: {},
    props: {
        media: {
            type: Array,
            required: true,
        },
        selectable: {
            type: Boolean,
            requried: false,
            default: false,
        },
    },
    data() {
        return {
            mediaData: this.media,
            categories: [
                "Application",
                "Authorization",
                "Identification",
                "Income",
                "Other",
            ],
        };
    },
    computed: {},
    watch: {
        media: {
            immediate: true,
            handler(media) {
                this.mediaData = media;
            },
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        updateFileCategory(file, category) {
            let index = -1;
            if (this.mediaData && Array.isArray(this.mediaData)) {
                index = this.mediaData.findIndex(
                    (media) => media.id === file.id
                );
            }
            if (index !== -1) {
                let media = this.mediaData[index];
                media.category = category;
                this.mediaData.splice(index, 1, media);
            }
            this.$emit("update:media", this.mediaData);
        },
    },
};
</script>

<style scoped>
</style>