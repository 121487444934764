var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { dense: "", color: "secondary", dark: "" } },
        [_c("v-toolbar-title", [_vm._v("Files")])],
        1
      ),
      _c(
        "v-card",
        { attrs: { outlined: "", flat: "" } },
        [
          _vm.media
            ? _c(
                "v-list",
                [
                  _vm._l(_vm.media, function(file, index) {
                    return [
                      _c(
                        "v-list-item",
                        { key: index, staticClass: "align-center" },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-5",
                                  attrs: { large: "", color: "primary" }
                                },
                                [_vm._v("mdi-file")]
                              )
                            ],
                            1
                          ),
                          _c("v-list-item-content", [
                            _c("div", { staticClass: "d-flex align-center" }, [
                              _c(
                                "a",
                                { attrs: { href: file.url, target: "_blank" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-truncate text-body-1",
                                      staticStyle: {
                                        display: "block",
                                        width: "430px"
                                      }
                                    },
                                    [_vm._v(_vm._s(file.name))]
                                  )
                                ]
                              ),
                              file.category || _vm.selectable
                                ? _c("div", { staticClass: "ml-4" }, [
                                    _vm.selectable
                                      ? _c(
                                          "div",
                                          { staticStyle: { width: "180px" } },
                                          [
                                            _c("v-select", {
                                              staticClass: "mt-5",
                                              attrs: {
                                                dense: "",
                                                solo: "",
                                                items: _vm.categories,
                                                value: file.category
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.updateFileCategory(
                                                    file,
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(_vm._s(file.category))
                                        ])
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _c("v-list-item-action", [
                            _c(
                              "a",
                              { attrs: { href: file.url, target: "blank" } },
                              [
                                _c(
                                  "v-btn",
                                  { attrs: { icon: "", color: "primary" } },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-download")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      index < _vm.media.length - 1
                        ? _c("v-divider", { key: "divider-" + index })
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }